import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import PrivacyPolicy from "../components/privacyPolicy"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import CallToAction from "../container/ctaContainer"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="プライバシーポリシー | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <SecondPageHeader englishTitle="- PRIVACY POLICY -" mainTitle="プライバシーポリシー" subTitle="個人情報保護方針" isShowTrialLink={false} />
    <div className="PrivacyPolicy secondPageContens">
      <PrivacyPolicy/>
    </div>
    <CallToAction />
  </Layout>
)

export default PrivacyPolicyPage
