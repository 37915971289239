import { Link } from "gatsby"
import React from "react"
import { Row } from "react-bootstrap"

const PrivacyPolicy = props => {
  return (
    <Row className="privacyPolicyContainer">
      <div className="col-sm-12 col-md-10 col-lg-8">
        <div className="termAndPolicyContents">
          <p>株式会社ソノリテ (以下「当社」といいます。) は、当社がMicrosoft Teams上で提供するワカチエサービス（以下「本サービス」といいます。）においてお客様の個人情報をお預かりするにあたり、以下のとおり個人情報保護方針を定めます。</p>
        
          <div className="termArticle">
            <h3>1. 個人情報の取扱い</h3>
            <p>
              当社は、個人情報の取扱いに関し、個人情報の保護に関する法律及び個人情報の保護に関する法律についてのガイドラインその他関連法令・ガイドライン等を遵守するとともに、当社のプライバシーポリシー（URL：
              <Link to="https://sonorite.co.jp/privacypolicy/" className="privacypolicyLink">https://sonorite.co.jp/privacypolicy/</Link>
              ）に従って適切に取扱います。
            </p>
          </div>

          <div className="termArticle">
            <h3>2. 個人情報の取得</h3>
            <p>当社は、本サービス提供に必要な範囲において、お客様の個人情報をお客様から取得し、又はMicrosoftから適切に取得します。</p>
            <p className="termSubParagraph">
              (1) サービス利用者情報<br />
              担当者の氏名、会社名、連絡先（メールアドレス等）
            </p>
            <p className="termSubParagraph">
              (2)ユーザー情報<br />
              ユーザー識別子、所属するサービス利用者の識別子、表示名、参加したTeams会議のID、会議参加中のログ
            </p>
          </div>

          <div className="termArticle">
            <h3>3. 個人情報の利用目的</h3>
            <p>当社は、本サービスにおいて取得した個人情報を以下の目的のために利用致します。</p>
            <p className="termSubParagraph">
              (1) サービス利用者情報
            </p>
            <ul>
              <li>
                本サービスの円滑かつ適切な提供のため
              </li>
              <li>
                お問い合わせ、ご連絡対応のため
              </li>
              <li>
                当社主催の催し物、取扱い商品、サービスに関するご案内や営業活動のため
              </li>
            </ul>
            <p className="termSubParagraph">
              (2)ユーザー情報
              ユーザー識別子、所属するサービス利用者の識別子、表示名、参加したTeams会議のID、会議参加中のログ
            </p>
            <ul>
              <li>
                本サービスの利用状況の把握のため
              </li>
              <li>
                本サービスの改良・改善のため
              </li>
              <li>
                本サービス向上のための分析・調査のため
              </li>
            </ul>
          </div>

          <div className="termArticle">
            <h3>4. 個人情報の第三者への開示・提供について</h3>
            <p>当社は、ご本人の同意を得ている場合や以下の場合を除き、取得した個人情報を第三者に提供することは致しません。</p>
            <p className="termSubParagraph">
              A)	お客様の同意がある場合

            </p>
            <p className="termSubParagraph">
              B)	法令に基づいて個人データを提供する場合
            </p>
            <p className="termSubParagraph">
              C)	お客様及び第三者の生命・身体・財産などに重大な損害が発生することを防止するために必要と判断した場合
            </p>
            <p className="termSubParagraph">
              D)	本サービス提供にあたり当社が業務を委託する業者に対して開示する場合
            </p>
            <p className="termSubParagraph">
              E)	当社の合併・分社化・営業譲渡などによる事業の承継に伴って、個人情報を提供する場合
            </p>
          </div>
          
          <div className="termArticle">
            <h3>5. 本方針の改定</h3>
            <p>当社は、本方針を変更した場合は、速やかに当社Webサイト上でお知らせいたします。</p>
          </div>
          
          <div className="date">
            <p>制定年月日 2023年6月1日</p>
          </div>

        </div>

      </div>   
    </Row>
  )
}

export default PrivacyPolicy
